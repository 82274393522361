.story-wrapper {
  position: relative;
  max-width: 550px;
  margin: 0px auto;
}
.story-item {
  width: 110px;
  flex-shrink: 0;
}

@media only screen and (max-width: 768px) {
  .story-item {
    width: 80px;
  }

  .story-title {
    font-size: 14px;
  }
}
