.content-modal-video-container {
  position: fixed;
  width: 50%;
  left: 25%;
  z-index: 101;
}

.content-modal-video {
  position: absolute;
  width: 100%;

  /* transform: translate(-50%, -50%); */
  z-index: 300;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.content-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 301;
  background: white;
  opacity: 1;
  border-radius: 50%;
  line-height: 27px;
  height: 30px;
  width: 30px;
  text-align: center;
  color: black;
  border: none;
  font-size: 28px;
  font-weight: bold;
  box-shadow: 0px 0px 10px #222;
}

.content-modal-close-btn:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

@media only screen and (max-width: 768px) {
  .content-modal-video-container {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50% !important;
  }
}
