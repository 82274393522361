.temp1-max-logo {
  width: 300px;
  position: fixed;
  top: 50vh;
}
.page-text {
  font-size: 2.6em;
}
@media only screen and (max-width: 768px) {
  .page-text {
    font-size: 1.6em;
  }
  .temp1-max-logo {
    width: 100px;
    position: absolute;
    top: 80px;
  }
  .back-image {
    min-height: 200px;
    background-position-y: 50%;
    background-position-x: 50%;
    background-size: cover;
    box-shadow: 0px 0px 15px #444;
    position: relative;
  }
  .admin-all-contents-img {
    background-image: url("../../images/admin-page-images/user.jpg");
  }
  .admin-user-contents-img {
    background-image: url("../../images/admin-page-images/user.jpg");
  }
  .admin-add-user-img {
    background-image: url("../../images/admin-page-images/create.jpg");
  }
  .admin-user-progress-img {
    background-image: url("../../images/admin-page-images/user-progress.jpg");
  }
  .admin-user-added {
    background-image: url("../../images/admin-page-images/added-user.jpg");
  }

  .user-all-contents-img {
    background-image: url("../../images/admin-page-images/user.jpg");
  }
  .user-progress-img {
    background-image: url("../../images/admin-page-images/user-progress.jpg");
  }
}
