.switch {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 34px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #021652;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .switch .slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 34px;
    left: -2px;
    bottom: 0px;
    background-color: #8be9e0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .switch .checked + .slider {
    background-color: #8be9e0;
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #28a745;
  }
  
  .switch .checked + .slider:before {
    background-color: #021652;
    -webkit-transform: translateX(44px);
    -ms-transform: translateX(44px);
    transform: translateX(44px);
  }
  .switch .slider {
    font-size: 1.5em;
    color: black;
    font-weight: bolder;
  }
  .switch .textOn {
    color: #021652;
    margin-left: -22px;
    margin-top: -3px;
  }
  .switch .textOff {
    color: white;
    margin-left: 22px;
    margin-top: -3px;
  }
  .switch .slider .textOn {
    display: none;
  }
  .switch .checked + .slider .textOn {
    display: block;
  }
  .switch .slider .textOff {
    display: block;
  }
  .switch .checked + .slider .textOff {
    display: none;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  