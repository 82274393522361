.content-carousel-images {
  display: flex;
  /* overflow-x: scroll; */
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.content-carousel-images::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.carousel-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.content {
  display: inline-block;
  margin-left: 20px;
  border-radius: 3px;
  max-width: 300px;
  min-width: 300px;
}
/* 
.content:hover {
  cursor: pointer;
} */

.content-disabled {
  background-color: #444;
  opacity: 0.5;
  /* pointer-events: none; */
}

.content-image {
  width: 300px;
  height: 150px;
  object-fit: cover;
  border-radius: 3px 3px 0px 0px;
}

.content-disabled-msg {
  min-height: 250px;
}

.scroll-left-button,
.scroll-right-button {
  position: absolute;
  top: calc(50% - 20px);
  background: none;
  border: none;
}
.scroll-left-button {
  right: -50px;
}
.scroll-right-button {
  left: -50px;
}

.eye-img {
  width: 20px;
  position: absolute;
  bottom: 12.5px;
  margin-right: -5px;
}

.heart-img {
  width: 18px;
  position: absolute;
  bottom: 10px;
  margin-right: -10px;
}

.heart-img-zoom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.content-disabled-msg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height: 20%; */
  width: 400px;
  background: white;
  border: 2px solid #231162;
  border-radius: 10px;
  z-index: 402;
  padding: 20px;
  text-align: center;
}

.screen-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 401;
  margin: 0 auto;
}

.clock-img {
  zoom: 0.8;
}

@media only screen and (max-width: 768px) {
  .mobile-vh10 {
    padding-top: 10vh;
  }

  .eye-img,
  .heart-img {
    width: 15px;
  }
  .carousel-container {
    width: 95%;
  }

  .content {
    margin-left: 10px;
  }

  .content-image {
    width: 150px;
    height: 100px;
  }

  .content-title {
    font-size: 22px;
  }

  .clock-img {
    zoom: 0.8;
  }

  .content-disabled-msg {
    width: 90%;
  }

  .mobile-mt2 {
    margin-top: 20px;
  }
}
