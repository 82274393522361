.content-box {
  padding-bottom: 10px;
  /* padding-bottom: 30px;*/
}

.content-ruller {
  color: white;
  font-size: 1.2em;
  padding: 12px 20px 0px 20px;
  min-height: 55px;
  background: #231162;
  width: 100%;
}
.content-eye-img {
  width: 20px !important;
  /*position: absolute;
  bottom: 0px;
  bottom: 2.5px;*/
  margin-right: -5px;
}

.content-heart-img {
  width: 18px !important;
  /*
  position: absolute;
  bottom: 0px;
  bottom: 0px;
  */
  margin-left: -5px;
}

.content-heart-img-zoom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.in-content-iframe {
  margin: 0px;
  padding: 0px;
  margin-bottom: -6px;

  height: 100vh;
}
.in-content-video {
  margin: 0px;
  padding: 0px;
  /*object-fit: fill;*/
  width: 100%;
  margin-bottom: -6px;
  /* max-height: 500px; */
}
.in-content-image {
  margin: 0px;
  padding: 0px;
  /*object-fit: fill;*/
  width: 100%;
  margin-bottom: -6px;
}
@media only screen and (max-width: 768px) {
  .content-ruller {
    min-height: 35px;
    padding: 4px 20px 0px 20px;
  }

  .content-eye-img,
  .content-heart-img {
    width: 15px !important;
  }

  .in-content-video {
    /* .in-content-iframe { */
    max-height: 250px;
  }

  .in-content-iframe {
    height: 80vh !important;
  }
}
