.temp1-max-logo {
  width: 300px;
  position: fixed;
  top: 50vh;
}
.page-text {
  font-size: 2.6em;
}
.minimal-nav {
  position: absolute;
  width: 100%;
  top: 0px;
  padding: 20px 25px 20px 25px;
  z-index: 200;
}
.minimal-nav .close-menu-btn-minimal {
  font-weight: bolder;
  color: #231162;
  font-size: 2.5em;
  position: absolute;
  right: 15px;
  top: 0px;
}
@media only screen and (max-width: 768px) {
  .page-text {
    font-size: 1.6em;
  }
  .temp1-max-logo {
    position: absolute;
    width: 100px;
    top: 60px;
  }
  .back-image {
    height: 200px;
    background-position-y: 50%;
    background-position-x: 50%;
    background-size: cover;
    box-shadow: 0px 0px 15px #444;
    position: relative;
  }
}
