.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr !important;
}

.text-blue {
  color: #231162;
}
.text-error {
  color: #ff0000;
}
.error-line {
  min-height: 25px;
}
.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.max-btn {
  background: #231162;
  border: none;
  padding: 0px 20px 7.5px 20px;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: 2em;
}
.max-form-btn {
  background: #231162;
  border: none;
  padding: 0px 20px 4.5px 20px;
  border-radius: 30px;
  color: white;

  font-size: 2em;
}
.text-underline {
  text-decoration: underline;
}

.input-field {
  border: none;
  border-bottom: 2px solid #231162;
  color: #231162 !important;
  display: block;
  background: none;
  margin: 0 auto;
  font-size: 2em;
  direction: rtl;
  width: 100%;
}

.input-field::placeholder {
  color: #231162;
}

.login-text {
  font-size: 4em;
}

.input-field-container {
  width: 30%;
  margin: 0 auto;
}
.input-field-container-create-user {
  width: 40%;
  margin: 0 auto;
}
.input-field-container-edit-user {
  width: 80%;
  margin: 0 auto;
}
.selectleumi--is-rtl {
  margin-top: -6px;
}
.selectleumi__control--is-focused {
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px;
}

.selectleumi__value-container {
  padding: 0px !important;
}
.selectleumi__control {
  border-color: #231162 !important;
  border-style: solid !important;
  border-width: 0px 0px 2px 0px !important;
  background: none !important;
  border-radius: 0px !important;
  font-size: 2em;
  width: 100%;
  color: #231162;
}
.selectleumi__placeholder {
  color: #231162 !important;
}
.selectleumi__control:hover {
  border-bottom: 2px solid #231162 !important;
  color: #231162 !important;
}
.selectleumi__indicator {
  color: #231162 !important;
}
.selectleumi__indicator-separator {
  background-color: #231162 !important;
}
.selectleumi__single-value {
  color: #231162 !important;
}
.selectleumi__menu {
  z-index: 202 !important;
}
.hidden-mobile-d-flex {
  display: flex !important;
}
.bg-teal {
  background-color: #479ba2;
}

.text-teal {
  color: #479ba2;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.shown {
  display: block;
}

.hidden {
  display: none !important;
}

.margin-up-10 {
  margin-top: 100px;
}

.margin-up-20 {
  margin-top: 200px;
}

.max-logo-small {
  zoom: 2;
}
.homeButton {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.homeButton > img {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.content-top {
  height: 60px;
}
.blue-home-button {
}
.blue-home-button > img {
  float: right;
  cursor: pointer;
  margin: 20px 0px 0px 10px;
  width: 30px;
  height: 30px;
}
.blue-max-top > img {
  float: left;
  cursor: pointer;
  margin: 25px 10px 0px 0px;
  width: 80px;
}
.bg-max-blue {
  background-color: #231162;
}

.page-title {
  padding-top: 100px;
  text-align: center;
  font-size: 3em;
  color: #231162;
}

.font-2em {
  font-size: 2em;
}

.font-3em {
  font-size: 3em;
}

.font-1dot2em {
  font-size: 1.2em;
}

.font-20px {
  font-size: 20px;
}
.text-right {
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .hidden-mobile-d-flex {
    display: none !important;
  }
  .hidden-mobile {
    display: none !important;
  }
  .input-field-container {
    width: 70%;
  }
  .input-field-container-create-user {
    width: 80%;
  }
  .input-field-container-edit-user {
    width: 80%;
  }
  .input-field {
    font-size: 1.2em;
  }
  .selectleumi__control {
    font-size: 1.2em;
  }
  .datePicker-text {
    font-size: 1.2em !important;
  }
  .datePicker-clear {
    padding: 4px !important;
  }
  .max-form-btn {
    font-size: 1.3em;
  }
  .mobile-order-1 {
    order: 1;
  }
  .page-title {
    padding-top: 0px;
    font-size: 2em;
  }
}

@media only screen and (min-width: 768px) {
  .show-mobile {
    display: none;
  }
}
div.grecaptcha-badge {
  visibility: hidden;
}
.showRecaptcha div.grecaptcha-badge {
  visibility: visible;
}

input[type="file"] {
  line-height: 20px;
}
