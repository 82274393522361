.table-container {
  color: #231162;
  font-size: 1.6em;
  max-width: 1000px;
  margin: 0px auto;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.table-container::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.user-table {
  margin: auto;
  text-align: center;
  padding: 0px;
  table-layout: fixed;
  border-collapse: collapse;
}
.user-table tbody {
  display: block;
  width: 100%;

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: 500px;
}
.user-table tbody::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.user-table thead tr {
  display: block;
}
.user-table tr > td:first-child {
  width: 25px;
  padding: 0px 0px 0px 0px;
}
.user-table tr > td {
  padding: 0px 20px 0px 20px;
}
.user-table tr {
  border-bottom: 1px #c0c5d4 solid;
}
.user-table .noBorder {
  border: 0px;
}
.user-table .editButton {
  background-color: transparent;
  border: 0px;
  display: block;
  background-image: url("../../../../images/edit.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.user-table .tcol-1 {
  width: 25px;
}
.user-table .t-col-2,
.user-table .t-col-3,
.user-table .t-col-4,
.user-table .t-col-5,
.user-table .t-col-6,
.user-table .t-col-7 {
  width: 150px;
}

.searchInput {
  direction: rtl;
  width: 50%;
  padding: 0px 10px 0px 10px;
  background-color: #e8e8e8;
  border: 1px #fcfcfc solid;
  color: #231162;
  font-size: 1.5em;
}
.searchInput::placeholder {
  color: #231162;
}
.activeSec {
  margin-top: 15px;
  margin-bottom: 15px;
}
.activeSec-text {
  padding-right: 20px;
  font-size: 1.5em;
  color: #231162;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
  display: inline-block;
}
.csv-export-button {
  background-color: #231162;
  color: white;
  font-size: 1.3em;
  border: 0px;
  width: 150px;

  margin: 5px 0px 5px 0px;
  margin-top: 20px;
  border-radius: 15px 15px;
}

.user-not-active {
  background-color: rgb(128, 128, 128, 0.1);
  color: gray !important;
}

.user-not-active a {
  color: gray !important;
}

@media only screen and (max-width: 768px) {
  .searchInput {
    width: 90%;
    font-size: 1em;
    font-weight: bolder;
  }
  .searchInput::placeholder {
    font-weight: bolder;
  }
  .user-table tbody {
    height: auto;
  }
  .table-container {
    font-size: 1.3em;
    width: 300px;
    margin: auto;
    overflow-x: auto;
    height: 50vh;
  }
  .user-table tbody {
    width: 650px;
  }
  .user-table .tcol-1 {
    width: 25px;
  }
  .user-table .t-col-2,
  .user-table .t-col-3,
  .user-table .t-col-4,
  .user-table .t-col-5,
  .user-table .t-col-6,
  .user-table .t-col-7 {
    width: 120px;
  }
  .user-table tr > td:first-child {
    width: 25px;
    padding: 0px 0px 0px 0px;
  }
  .user-table tr > td {
    padding: 0px 0px 0px 0px;
  }
  .user-table .editButton {
    width: 15px;
    height: 15px;
  }
}
