@font-face {
  font-family: "max-regular";
  font-display: swap;
  src: url("./font/MAX-Light.eot");
  src: url("./font/MAX-Light.eot?#iefix") format("embedded-opentype"),
    url("./font/MAX-Light.woff") format("woff"),
    url("./font/MAX-Light.ttf") format("truetype");
}

@font-face {
  font-family: "max-regular-normal";
  font-display: swap;
  src: url("./font/MAX-Regular.eot");
  src: url("./font/MAX-Regular.eot?#iefix") format("embedded-opentype"),
    url("./font/MAX-Regular.woff") format("woff"),
    url("./font/MAX-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "max-regular-bold";
  font-display: swap;
  src: url("./font/MAX-Medium.eot");
  src: url("./font/MAX-Medium.eot?#iefix") format("embedded-opentype"),
    url("./font/MAX-Medium.woff") format("woff"),
    url("./font/MAX-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "max-regular-bolder";
  font-display: swap;
  src: url("./font/MAX-Bold.eot");
  src: url("./font/MAX-Bold.eot?#iefix") format("embedded-opentype"),
    url("./font/MAX-Bold.woff") format("woff"),
    url("./font/MAX-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./images/background.png");
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: cover;
  font-family: max-regular !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:focus {
  outline: none !important;
}

a,
a:hover {
  color: #231162;
  text-decoration: none;
}
