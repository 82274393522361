.ob-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ob-next,
.ob-prev {
  top: 50%;
  transform: translateY(-50%);
}

.ob-next,
.ob-prev:hover {
  cursor: pointer;
}

.ob-next {
  position: absolute;
  left: 20px;
}

.ob-prev {
  position: absolute;
  right: 20px;
}

.ob-image {
  height: 75vh;
}

.ob-button {
  padding: 10px 20px;
  background: #8cead5;
  color: #1d1c64;
  font-weight: bold;
  font-size: 1.4em;
  border-radius: 2px;
  border: none;
  box-shadow: 5px 5px 10px #333;
}

.ob-button-img {
  animation: goLeft ease-out 1.5s infinite;
  padding-bottom: 2px;
}

@keyframes goLeft {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-5px);
  }
}
