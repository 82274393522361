.login-img {
  background: url("../../images/login.jpg");
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: cover;
  box-shadow: 0px 0px 15px #444;
}
.logindisable:disabled {
  background-color: gray;
}
@media only screen and (max-width: 768px) {
  .login-text {
    font-size: 2.6em;
  }

  .login-text {
    margin-top: 0px !important;
  }

  .max-login-btn {
    margin-top: 10px !important;
    margin-bottom: 15px;
  }
}
