.top-navbar {
  background-color: #231162;
  color: white;
  font-weight: bold;
  font-size: 1.4em;
  margin: 0px !important;
  padding: 10px !important;
  position: fixed;
  z-index: 100;
}

.top-navbar li {
  display: inline-block;
}

.top-navbar a {
  color: white;
}

.top-navbar a:hover {
  color: white;
}

.top-navbar .active {
  color: #67eed5;
}

.mobile-nav .mobile-nav-logo {
  position: fixed;
  right: 50%;
  top: 10px;
  zoom: 2.2;
  transform: translateX(50%);
}

.mobile-nav .mobile-nav-hamburger {
  z-index: 199;
  position: fixed;
  right: 15px;
  top: 32.5px;
  width: 40px;
}

.mobile-nav .mobile-nav-notification {
  position: fixed;
  left: 15px;
  top: 30px;
  width: 50px;
  z-index: 199;
}
.nav-notification:hover {
  cursor: pointer;
}

.notification-count-mobile {
  position: fixed;
  top: 2px;
  left: 35px;
  background-color: rgb(224, 0, 0);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  font-weight: bold;
}

.top-navbar .nav-notification {
  width: 30px;
}

.top-navbar .notification-count {
  background-color: rgb(224, 0, 0);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

.mobile-nav-container {
  position: fixed;
  right: 0;
  top: 3%;
  z-index: 302;
}

.mobile-nav-container .mobile-nav {
  border: 2px solid #231162;
  border-right: none;
  border-radius: 15px 0px 0px 15px;
  padding: 50px 30px 20px 30px;
  background: white;
  font-weight: bold;
  color: #231162;
  /* animation: slide-in-right 0.3s; */
}

.mobile-nav-container li {
  display: block;
  text-align: right;
  padding: 10px 0px;
  border-bottom: 1px solid #231162;
  font-size: 20px;
}

.close-menu-btn {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 50px;
  font-weight: bold;
}

.close-menu-btn:hover {
  cursor: pointer;
}

.log-out-mobile-btn {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}

.notification-type-img {
  width: 30px;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.notification-menu {
  position: fixed;
  background: white;
  border: 2px solid #231162;
  border-radius: 15px 15px;
  left: 15%;
  width: 500px;
  top: 60px;
  color: #231162;
  z-index: 301;
}

.notification-border-bottom {
  /* border-bottom: 2px solid #231162; */
}
.notification-container {
  overflow-y: auto;
  max-height: 600px;
}
.logout-dialog {
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  width: 300px;
  min-height: 100px;
  background: white;
  border: 2px solid #231162;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  z-index: 403;
  direction: rtl;
}
.logout-dialog button {
  color: #231162;
  font-weight: bolder;
  border: 2px solid #231162;
  border-radius: 10px;
  background-color: white;
  margin: 10px;
  padding: 0px 6px;
}
@media only screen and (max-width: 768px) {
  .mobile-nav {
  }

  .notification-menu {
    width: 80%;
    left: -5%;
    top: 3%;
    /* animation: slide-in-left 0.3s; */
  }
  .notification {
    font-size: 16px;
  }
  /* .notification-border-bottom {
    border-bottom: 1px solid #231162;
  } */
  .notification-container {
    max-height: 50vh;
  }
}

/* Try make circle notifications */

/* .notification-count-circle {
  width: 30px;
  height: 30px;
  background: #706679;
  border-radius: 15px;
  margin: 0 auto;
}

.notification-count {
  display: block;
  color: #f7f7f7;
  font: normal 14px "Times New Roman", Georgia;
  text-align: center;
  line-height: 30px;
} */

.new-nav {
  padding: 10px;
  background: #ffffff;
  height: 10vh;
  align-items: end;
  display: flex;
}

.new-nav .new-max-logo {
  width: 80px;
  margin: 0 auto;
}

.new-nav .new-max-envelope {
  width: 37.5px;
  height: 27px;
}

.new-nav .new-back {
  width: 37.5px;
  height: 27px;
}

.new-nav .new-max-hamburger {
  width: 37.5px;
  height: 20px;
}

.new-notification-count {
  position: fixed;
  top: 20px;
  left: 50px;
  background-color: rgb(224, 0, 0);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

/* if fixed navbar */
/*  */
/*  */

.new-nav-fixed {
  padding: 10px;
  background: #ffffff;
  height: 8vh;
  align-items: end;
  /* display: flex; */
  position: fixed;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.new-nav-fixed .new-max-logo {
  width: 80px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.new-nav-fixed .new-max-envelope {
  width: 37.5px;
  height: 27px;
}

.new-nav-fixed .new-back {
  width: 37.5px;
  height: 27px;
}

.new-nav-fixed .new-max-hamburger {
  width: 37.5px;
  height: 20px;
  float: right;
}
