.welcome-img {
  background: url("../../images/welcome.jpg");
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: cover;
  box-shadow: 0px 0px 15px #444;
}
/*
.screen-centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
  width: 100%;
}
*/

.screen-centered {
  width: 100%;
  text-align: center;
}
.max-logo {
  width: 300px;
}

.welcome-text {
  font-size: 4em;
}

@media only screen and (max-width: 768px) {
  .max-logo {
    width: 200px;
  }

  .welcome-text {
    font-size: 2em;
  }
}
