.datePicker-box {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 18px;
  min-height: 66px;
}
.datePicker-text {
  z-index: 100;
  position: absolute;
  border-color: #231162 !important;
  border-style: solid !important;
  border-width: 0px 0px 2px 0px !important;
  background: none !important;
  border-radius: 0px !important;
  font-size: 2em;
  width: 100%;
  color: #231162;
}
.datePicker-clear {
  z-index: 101;
  padding: 8px;
  position: absolute;
  color: #231162;
}

.ltr-picker .datePicker-clear {
  right: 0px;
}
.rtl-picker .datePicker-clear {
  left: 0px;
}
.rtl-picker {
  direction: rtl;
  text-align: right;
}
.rtl-picker .react-datepicker__navigation--previous {
  transform: rotate(180deg);
  right: 10px;
  left: auto;
}
.rtl-picker .react-datepicker__navigation--next {
  transform: rotate(180deg);
  left: 10px;
  right: auto;
}
