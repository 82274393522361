.stories-container {
  width: 550px;
  display: flex;
  overflow-x: hidden;
}

.story-img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  padding: 2px;
  margin: 0 5px;
}

.story {
  width: 200px;
}

.unviewed-story {
  border: 2px solid lightskyblue;
}
.viewed-story {
  border: 2px solid lightgray;
}

.story-img:hover {
  cursor: pointer;
}

.scroll-left-btn,
.scroll-right-btn {
  position: absolute;
  background: none;
  border: none;
  font-size: 2em;
  color: gray;
  top: 30px;
  background-color: whitesmoke;
  border-radius: 25px;
  line-height: 20px;
  padding: 0px 10px 10px 10px;
  margin: 0px 5px;
  box-shadow: 2.5px 2.5px 5px lightgray;
}

.scroll-left-btn {
  left: -55px;
}

.scroll-right-btn {
  right: -55px;
}
.feed-sec {
  max-width: 550px;
  /*min-width: 360px;//shay */
  margin: 0px auto;
}
.feed-container {
  height: 500px;
  max-width: 550px;
  /* padding: 0px 15px 0px 15px; */
  /* min-width: 320px; */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.feed-container::-webkit-scrollbar {
  width: 0 !important;
  display: none;
} /*shay*/

.feed-content-container {
  margin: 20px auto 20px auto;
  max-width: 500px;
  min-width: 280px;
  text-align: right;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  position: relative;
}
.feed-category {
  position: absolute;
  top: 150px;
  text-align: center;
  right: 10px;
  background-color: #ec6685;
  /*color: #1a435e;*/
  color: white;
  font-weight: bold;
  height: 30px;
  min-width: 130px;
  line-height: 30px;
  padding: 0px 15px 0px 15px;
}
.content-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.content-title {
  color: white;
  font-size: 30px;
  font-weight: bolder;
  padding: 5px 30px 0px 30px;
}
.content-description {
  color: white;
  padding: 0px 30px 10px 30px;
}
/*
.content-time,
.content-status {
  color: #479ba2;
}
*/

.content-footer {
  padding: 0px 30px 15px 30px;
}
.big-text-blue {
  direction: rtl;
  color: #231162;
  font-size: 1.6em;
  text-align: center;
  padding: 20px;
}
.feed-blue {
  background-color: #06174f;
}
.feed-blue .feed-text {
  color: white;
}
.feed-blue .feed-other-text {
  color: #87e3d0;
}
.feed-light-blue {
  background-color: #5cccfa;
}
.feed-light-blue .feed-text {
  color: #06174f;
}
.feed-light-blue .feed-other-text {
  color: white;
}

.new-feed-container {
  margin-top: 100px;
}

@media only screen and (max-width: 768px) {
  .stories-container {
    width: 100%;
    overflow-x: scroll;
  }

  .scroll-left-btn,
  .scroll-right-btn {
    display: none;
  }

  .feed-container,
  .feed-content-container {
    width: 100%;
  }

  .feed-container {
    /* margin-top: 20px; */
    height: 70vh;
  }

  .stories-container {
    height: 20vh;
    overflow-y: hidden;
  }

  .feed-box {
    padding: 0px;
    margin: 0px;
    z-index: 1;
  }

  .new-feed-container {
    margin-top: 0px;
  }

  .story-img {
    width: 70px;
    height: 70px;
  }
}

.rc-slider-vertical .rc-slider-step {
  background-color: #231162;
}
