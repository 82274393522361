.overlay {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: black;
  position: fixed;
  opacity: 0.5;
  z-index: 101;
}
