.in-admin-menu .image-menu {
  display: flex;
  height: 80px;
  background-color: lightcoral;
  color: white;
  border-radius: 7px;
  margin: 0px 0px 30px 0px;
  font-size: 1.3em;
  font-weight: bold;
}

.in-admin-menu .image-menu-text {
  padding: 0px 30px 0px 30px;
}
.in-admin-menu .image-menu-img {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80px;
  box-shadow: -3px 3px 10px rgb(0, 0, 0, 0.5);
  background-size: 100% 100%;
  border-radius: 0px 7px 7px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.in-admin-menu .image-menu-text {
  flex: 7 1 auto;
  align-self: center;
}
.in-admin-menu .all-users {
  background-color: #ec6685;
  /* ww */
}
.in-admin-menu .all-users .image-menu-img {
  /* background-image: url("../../images/admin-menu/1.jpg"); */
  background-image: url("../../images/admin-menu/1.jpg");
}
.in-admin-menu .finished-users {
  background-color: #1a435e;
}
.in-admin-menu .finished-users .image-menu-img {
  /* background-image: url("../../images/admin-menu/2.jpg"); */
  background-image: url("../../images/admin-menu/2.jpg");
}
.in-admin-menu .low-users {
  color: #19425e;
  background-color: #8cead5;
}
.in-admin-menu .low-users .image-menu-img {
  /* background-image: url("../../images/admin-menu/3.jpg"); */
  background-image: url("../../images/admin-menu/3.jpg");
}
.in-admin-menu .late-users {
  background-color: #5dccfa;
}
.in-admin-menu .late-users .image-menu-img {
  /* background-image: url("../../images/admin-menu/4.jpg"); */
  background-image: url("../../images/admin-menu/4.jpg");
}

.in-admin-menu-web {
  margin: 0px 40px 0px 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-image: url("../../images/admin-menu/bg.jpg");
  background-size: cover;
  justify-content: center;
  height: 400px;
}
.in-admin-menu-web > .box-rowpan {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  width: 25%;
  min-width: 100px;
}
.in-admin-menu-web > .box {
  width: 25%;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
  font-size: 2em;
  padding: 30px;
  text-decoration: none;
  font-weight: bolder;
  max-height: 200px;
}
.l1 {
  color: #ffffff;
  background-color: #ec6685;
}
.box:hover {
  color: #1a435e;
  background-color: rgba(255, 255, 255, 0.5);
}
.l2 {
  color: #ffffff;
  background-color: #1a435e;
}
.l3 {
  padding: 0px 50px 0px 50px !important;
  color: #1a435e;
  background-color: #6eefdd;
}
.l4 {
  color: #ffffff;
  background-color: #5dccfa;
}
.l5 {
  color: #ffffff;
  background-color: #479ba2;
}
.l6 {
  color: #6eefdd;
  background-color: #06174f;
}

@media only screen and (max-width: 768px) {
  .admin-top-buttons {
  }
}
