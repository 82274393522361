.float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
  color: #231162;
}

.float-label input {
  width: 100%;
  height: 56px;
  padding: 14px 16px 0 10px;
  outline: 0;
  font-size: 1.5em;
}

.float-label textarea {
  width: 100%;
  height: 100px;
  padding: 14px 16px 0 10px;
  outline: 0;
  font-size: 1.5em;
  color: black;
}

.float-label label {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 1.5em) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-size: 1.2em;
  z-index: 1;
}

.float-label:focus-within label {
  transform: translate(0, 12px);
}

.float-label .Active {
  transform: translate(0, 12px);
}


