.login-img {
  background: url("../../images/login.jpg");
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: cover;
  box-shadow: 0px 0px 15px #444;
}
.my-vh100 {
  height: 100vh;
}
.ie-fix {
  display: none;
}
@media all and (-ms-high-contrast: none) {
  .ie-fix {
    height: 100vh;
    display: block;
  }
  .my-vh100 {
    display: flex;
    min-height: 100%;
    height: 100%;
  }
  .temp1-max-logo {
    margin-left: -150px;
  }
}
@media only screen and (max-width: 768px) {
  .login-text {
    font-size: 2.6em;
  }

  .login-text {
    margin-top: 0px !important;
  }

  .max-login-btn {
    margin-top: 10px !important;
    margin-bottom: 15px;
  }

  .my-vh100 {
    height: 80vh;
  }
}
