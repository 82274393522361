.carousel .slide {
  background-color: transparent;
}
.carousel .slide iframe {
  display: block;
  width: 100%;
  margin: 0px;
  border: 0px;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: inherit;
}
.carousel .control-dots {
  padding: 0px;
}
.carousel .control-dots .dot {
  border: 1px #231162 solid;
}
.carousel .control-dots .selected {
  background-color: #231162;
}
.car-wraper {
  position: relative;
  font-size: 24px;
}
.car-prev,
.car-next {
  border: 0px;
  background-color: transparent;
  background-size: contain;
  width: 50px;
  height: 50px;
  top: 50%;
  position: absolute;
  display: none;
}
.car-prev {
  background-image: url("../../images/arrow-right.png");
  right: -55px;
}
.car-next {
  left: -55px;
  background-image: url("../../images/arrow-left.png");
  display: none;
}
@media only screen and (max-width: 768px) {
  .car-prev,
  .car-next {
    display: none;
  }
}
