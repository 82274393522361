.story-video-container {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  z-index: 300;
  max-height: 90vh;
  transform: translate(-50%, -50%);
}

.story-video {
  width: 100%;
  z-index: 300;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4); */
}

.story-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 301;
  background: white;
  opacity: 0.8;
  border-radius: 50%;
  line-height: 27px;
  height: 30px;
  width: 30px;
  text-align: center;
  color: black;
  border: none;
  font-size: 28px;
  font-weight: bold;
  box-shadow: 0px 0px 10px #222;
}

.story-close-btn:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

@media only screen and (max-width: 768px) {
  .story-video-container {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
