.stat {
  font-size: 1.4em;
  font-weight: bold;
  color: #231162;
  padding-bottom: 20px;
  /* border-bottom: 1px solid #231162; */
}
.img-wrap {
  display: inline-block;
  width: 70px;
}
.handle {
  /* background-image: url("../../../images/arrow-left.png"); */
  height: 10px;
  width: 10px;
  background: #ec6685;
  border-radius: 50%;
  background-size: contain;
  width: 27px;
  height: 27px;
  margin: -11px -14px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .img-wrap {
    width: 50px;
  }
  .img-wrap img {
    max-width: 40px;
    max-height: 40px;
  }
  .crown {
    max-width: 40px;
  }

  .white-bg-mobile {
    background: white;
  }

  .stat {
    font-size: 1.2em;
  }
}

.rc-slider-rail,
.rc-slider-track {
  height: 3px !important;
  background-color: #231162;
}
