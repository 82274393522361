.sep {
  min-height: 16px;
}
.sent-image {
  margin-top: 150px;
  width: 30%;
}
.sent-text {
  text-align: center;
  font-size: 3em;
  color: #231162;
}
@media only screen and (max-width: 768px) {
  .sent-image {
    margin-top: 50px;
  }
  .sent-text {
    text-align: center;
    font-size: 1.5em;
    color: #231162;
  }
}
